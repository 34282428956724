interface Params {
  [key: string]: string | number | boolean | undefined;
}

const createUrlWithParams = (baseURL: string, params: Params): string => {
  const url = new URL(baseURL);
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined)
  ) as Record<string, string>;
  url.search = new URLSearchParams(filteredParams).toString();
  return url.toString();
};

const deleteSearchParams = (url: string, params: string[]): string => {
  const urlObj = new URL(url);
  params.forEach((param) => urlObj.searchParams.delete(param));
  return urlObj.toString();
};

const deleteSearchParamsAndRedirect = (params: string[]): void => {
  console.log("deleteSearchParamsAndRedirect params...", params);
  const url = deleteSearchParams(window.location.href, params);
  console.log("deleteSearchParamsAndRedirect url...", url);
  window.location.href = url;
};

export {
  createUrlWithParams,
  deleteSearchParams,
  deleteSearchParamsAndRedirect,
};
