/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from "react";
import { SSOContext, SSOContextProps } from "../context/SSOContext";

export interface AuthSSO extends SSOContextProps {}

const authSsrSSO: AuthSSO = {
  clientId: undefined,
  ssoUrl: undefined,
  ssoApiUrl: undefined,
  clientDomain: undefined,
  options: {},
  initialized: false,
  authenticated: false,
  subject: undefined,
  realmAccess: undefined,
  resourceAccess: undefined,
  token: undefined,
  tokenParsed: undefined,
  refreshToken: undefined,
  refreshTokenParsed: undefined,
  idToken: undefined,
  idTokenParsed: undefined,
  timeSkew: 0,
  login: () => {},
  register: () => {},
  loginSocial: () => {},
  accountManagement: () => {},
  logout: () => {},
};

const useAuthSSO = () => {
  if (typeof window === "undefined") return { authSSO: authSsrSSO };

  const context = useContext(SSOContext);
  if (context === undefined) {
    throw new Error("useAuthSSO must be used within a SSOProvider");
  }

  const authSSO: AuthSSO = context;

  return { authSSO };
};

export { useAuthSSO };
