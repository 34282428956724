import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import camelCase from "camelcase-keys";

interface CustomAxiosResponse<T = any> extends AxiosResponse {
  data: T;
}

const axiosClient = axios.create({
  responseType: "json",
  timeout: 15 * 1000,
});

axiosClient.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => config,
  (error: any): Promise<any> => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response: CustomAxiosResponse): CustomAxiosResponse => {
    response.data = camelCase(response.data, { deep: true });
    return response;
  },
  (error: any): Promise<any> => Promise.reject(error)
);

export default axiosClient;
