export const TIME_PKCE: number = 15 * 60 * 1000; // 15 minutes

export const KEY_COOKIE_DATA_PKCE: string = "pkce";

export enum RESPONSE_TYPE {
  CODE = "code",
  TOKEN = "token",
}

export enum CODE_CHALLENGE_METHOD {
  S256 = "S256",
  PLAIN = "plain",
}

export enum GRANT_TYPE {
  AUTHORIZATION_CODE = "authorization_code",
  REFRESH_TOKEN = "refresh_token",
}

export enum AUTH_ACTION {
  LOGIN = "login",
  SIGNUP = "signup",
  LOGOUT = "logout",
}

export const ERROR_MESSAGE_AUTH = ["user_already_exists"];
