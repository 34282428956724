import api from "./api";

interface TokenParams {
  grantType: string;
  clientId: string;
  codeVerifier: string;
  code: string;
  redirectUri: string;
  ssoApiUrl?: string;
}

interface RefreshTokenParams {
  clientId: string;
  refreshToken?: string;
  ssoApiUrl: string;
}

interface TokenResponseSuccess {
  result: {
    access_token: string;
    expires_in: number;
    id_token: string;
    not_before_policy: number;
    refresh_expires_in: number;
    refresh_token: string;
    scope: string;
    session_state: string;
    token_type: string;
    [key: string]: any;
  };
  status: number;
  [key: string]: any;
}

interface TokenResponseError {
  code?: number;
  message?: string;
  status?: number;
  [key: string]: any;
}

export type TokenResponse = TokenResponseSuccess | TokenResponseError;

interface ErrorResponse {
  [key: string]: any;
}

const getToken = async ({
  grantType,
  clientId,
  codeVerifier,
  code,
  redirectUri,
  ssoApiUrl,
}: TokenParams): Promise<TokenResponse | ErrorResponse> => {
  try {
    const response = await api({
      method: "POST",
      url: `${ssoApiUrl}/api/v1/oauth/token`,
      data: {
        grant_type: grantType,
        client_id: clientId,
        code_verifier: codeVerifier,
        code,
        redirect_uri: redirectUri,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data as ErrorResponse;
    }
    throw error;
  }
};

const refreshToken = async ({
  clientId,
  ssoApiUrl,
  refreshToken,
}: RefreshTokenParams): Promise<TokenResponse | ErrorResponse> => {
  try {
    const response = await api({
      method: "POST",
      url: `${ssoApiUrl}/api/v1/auth/refresh-token`,
      data: { clientId, refreshToken },
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data as ErrorResponse;
    }
    throw error;
  }
};

const logout = async ({
  clientId,
  ssoApiUrl,
}: RefreshTokenParams): Promise<TokenResponse | ErrorResponse> => {
  try {
    const response = await api({
      method: "POST",
      url: `${ssoApiUrl}/api/v1/logout`,
      data: { clientId, refreshToken },
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      return error.response.data as ErrorResponse;
    }
    throw error;
  }
};

export { getToken, refreshToken, logout };
