/* eslint-disable react-hooks/rules-of-hooks */
import {
  createContext,
  ReactNode,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  constructUrlAndRedirect,
  setupPkceAndSetCookie,
} from "../services/pkce";
import {
  AUTH_ACTION,
  CODE_CHALLENGE_METHOD,
  ERROR_MESSAGE_AUTH,
  GRANT_TYPE,
  KEY_COOKIE_DATA_PKCE,
  RESPONSE_TYPE,
} from "../constants";
import apis from "../apis";
import { getCookie, removeCookie } from "../utils/cookie";
import { deleteSearchParamsAndRedirect } from "../utils/url";
import { jwtDecode } from "../utils/jwt";

interface Options {
  [key: string]: any;
}

interface DataPkceCookie {
  /**
   * This is the code verifier used to generate the code challenge
   */
  codeVerifier: string;
  /**
   * This is the client ID used to authenticate the user
   */
  clientId: string;
  /**
   * This is the redirect URI used to redirect the user after the login or signup process
   */
  redirectUri: string;
}

interface SSOConfig {
  /**
   * This is the client ID used to authenticate the user
   */
  clientId: string | undefined;
  /**
   * This is URL to the SSO for the login or signup process
   */
  ssoUrl: string | undefined;
  /**
   * This is the URL to the SSO API for the token request
   */
  ssoApiUrl: string | undefined;
  /**
   * This is the domain to set the cookie
   */
  clientDomain: string | undefined;
  /**
   * This is the feature keys to check the optional features
   */
  options: Options | undefined;
}

interface Roles {
  roles: string[];
}

interface ResourceAccess {
  [key: string]: Roles;
}

interface TokenParsed {
  exp?: number;
  iat?: number;
  nonce?: string;
  sub?: string;
  session_state?: string;
  realm_access?: Roles;
  resource_access?: ResourceAccess;
}

interface LoginOptions {
  /**
   * Specifies the uri to redirect to after login.
   */
  redirectUri: string;
  /**
   * username to pre-fill in the login form.
   */
  username?: string;
  /**
   * This is the state data to pass to the redirect URI
   */
  state?: string;
}

interface LoginSocialOptions {
  /**
   * This is the redirect URI to redirect the user after the login or signup process
   */
  redirectUri: string;
  /**
   * This is the provider to use for the login or signup process (google, facebook, apple)
   */
  provider: "google" | "facebook" | "apple";
  /**
   * This is the state data to pass to the redirect URI
   */
  state?: string;
}

interface LogoutOptions {
  /**
   * Specifies the uri to redirect to after logout.
   */
  redirectUri?: string;
}

export interface SSOContextProps extends SSOConfig {
  /**
   * This is a boolean flag to check if the context is initialized
   */
  initialized: boolean | undefined;
  /**
   * This is a boolean flag to check if the user is authenticated
   */
  authenticated: boolean | undefined;
  /**
   * The user id.
   */
  subject: string | undefined;
  /**
   * The realm roles associated with the token.
   */
  realmAccess: Roles | undefined;
  /**
   * The resource roles associated with the token.
   */
  resourceAccess: ResourceAccess | undefined;
  /**
   * The base64 encoded token that can be sent in the Authorization header in
   * requests to services.
   */
  token: string | undefined;
  /**
   * The parsed token as a JavaScript object.
   */
  tokenParsed: TokenParsed | undefined;
  /**
   * The base64 encoded refresh token that can be used to retrieve a new token.
   */
  refreshToken: string | undefined;
  /**
   * The parsed refresh token as a JavaScript object.
   */
  refreshTokenParsed: TokenParsed | undefined;
  /**
   * The base64 encoded ID token.
   */
  idToken: string | undefined;
  /**
   * The parsed id token as a JavaScript object.
   */
  idTokenParsed: TokenParsed | undefined;
  /**
   * The estimated time difference between the browser time and the Keycloak
   * server in seconds. This value is just an estimation, but is accurate
   * enough when determining if a token is expired or not.
   */
  timeSkew: number | undefined;
  /**
   * Redirects to register form.
   * @param options Login options.
   */
  register?: (options: LoginOptions) => void;
  /**
   * Redirects to login form.
   * @param options Login options.
   */
  login?: (options: LoginOptions) => void;
  /**
   * Redirects to login form with social provider.
   * @param options Login options.
   */
  loginSocial?: (options: LoginSocialOptions) => void;
  /**
   * Redirects to account management.
   */
  accountManagement?: () => void;
  /**
   * Logs out the user.
   * @param options Logout options.
   */
  logout?: (options: LogoutOptions) => void;
}
interface SSOProviderProps {
  config: SSOConfig;
  children: ReactNode;
}

const SSOContext = createContext<SSOContextProps | undefined>(undefined);

const SSOProvider: FunctionComponent<SSOProviderProps> = ({
  config,
  children,
}) => {
  const { clientId, ssoUrl, ssoApiUrl, clientDomain, options } = config;

  if (!clientId || !ssoUrl || !ssoApiUrl || !clientDomain || !options) {
    throw new Error(
      "Please provide the clientId, ssoUrl, and ssoApiUrl in the config"
    );
  }

  const initialValue: SSOContextProps = {
    clientId,
    ssoUrl,
    ssoApiUrl,
    clientDomain,
    options,
    initialized: false,
    authenticated: false,
    subject: undefined,
    realmAccess: undefined,
    resourceAccess: undefined,
    token: undefined,
    tokenParsed: undefined,
    refreshToken: undefined,
    refreshTokenParsed: undefined,
    idToken: undefined,
    idTokenParsed: undefined,
    timeSkew: 0,
  };

  if (typeof window === "undefined")
    return (
      <SSOContext.Provider value={initialValue}>{children}</SSOContext.Provider>
    );

  const [contextValue, setContextValue] =
    useState<SSOContextProps>(initialValue);

  console.log("contextValue...", contextValue);

  const urlParams = new URLSearchParams(window.location.search);
  const codeParams = urlParams.get("code");
  const authAction = urlParams.get("action");
  const errorParams = urlParams.get("error") || "";
  const authError = ERROR_MESSAGE_AUTH.includes(errorParams);
  const { autoLogin, useSSO } = options || {};
  const keyDataPkceCookie = `${KEY_COOKIE_DATA_PKCE}-${clientId}-${clientDomain}`;

  console.log("urlParams...", {
    codeParams,
    authAction,
    errorParams,
    authError,
  });

  const handleAuth = useCallback(
    (options: LoginOptions, action: AUTH_ACTION) => {
      const { redirectUri, username, state } = options;
      console.log("handleAuth...", { redirectUri, username, state });
      const codeChallenge = setupPkceAndSetCookie(
        redirectUri,
        clientId,
        clientDomain
      );
      const params = {
        clientId,
        codeChallenge,
        codeChallengeMethod: CODE_CHALLENGE_METHOD.S256,
        redirectUri,
        responseType: RESPONSE_TYPE.CODE,
        action,
        username,
        state,
      };
      console.log("handleAuth...", params);
      constructUrlAndRedirect(`${ssoUrl}/authorize`, params);
    },
    [clientId, clientDomain, ssoUrl]
  );

  const register = useCallback(
    (options: LoginOptions) => handleAuth(options, AUTH_ACTION.SIGNUP),
    [handleAuth]
  );

  const login = useCallback(
    (options: LoginOptions) => handleAuth(options, AUTH_ACTION.LOGIN),
    [handleAuth]
  );

  const loginSocial = ({
    redirectUri,
    provider,
    state,
  }: LoginSocialOptions) => {
    const codeChallenge = setupPkceAndSetCookie(
      redirectUri,
      clientId,
      clientDomain
    );
    const params = {
      clientId,
      codeChallenge,
      codeChallengeMethod: CODE_CHALLENGE_METHOD.S256,
      redirectUri,
      responseType: RESPONSE_TYPE.CODE,
      state,
    };
    constructUrlAndRedirect(`${ssoApiUrl}/api/v1/auth/${provider}`, params);
  };

  const accountManagement = () => {
    const accountUrl = `${ssoUrl}/profile?client_id=${clientId}`;
    window.open(accountUrl, "_blank");
  };

  const logout = async ({ redirectUri }: LogoutOptions) => {
    try {
      const response = await apis.auth.logout({
        clientId: clientId as string,
        ssoApiUrl: ssoApiUrl as string,
      });
      if (response.status !== 1)
        throw new Error(response?.message || "Logout failed");

      if (redirectUri) window.location.href = redirectUri;
    } catch (error) {
      console.error("logout error", error);
    }
  };

  const updateContextValue = (result: any) => {
    const { accessToken, refreshToken, idToken } = result || {};
    const newContextValue = {} as SSOContextProps;

    if (accessToken) {
      const tokenParsed = jwtDecode(accessToken);
      newContextValue.token = accessToken;
      newContextValue.tokenParsed = tokenParsed;
      newContextValue.authenticated = true;
      newContextValue.initialized = true;
      newContextValue.subject = tokenParsed.sub;
      newContextValue.realmAccess = tokenParsed.realm_access;
      newContextValue.resourceAccess = tokenParsed.resource_access;
    }

    if (refreshToken) {
      newContextValue.refreshToken = refreshToken;
      newContextValue.refreshTokenParsed = jwtDecode(refreshToken);
    }

    if (idToken) {
      newContextValue.idToken = idToken;
      newContextValue.idTokenParsed = jwtDecode(idToken);
    }

    setContextValue((prevContextValue) => ({
      ...prevContextValue,
      ...newContextValue,
    }));
  };

  const refreshToken = useCallback(async () => {
    try {
      console.log("refreshToken...");
      const response = await apis.auth.refreshToken({
        clientId,
        ssoApiUrl,
      });

      console.log("refreshToken response...", response);

      if (response.status !== 1)
        throw new Error(response?.message || "Refresh token failed");

      updateContextValue(response?.result);
      removeCookie(keyDataPkceCookie, clientDomain);
    } catch (error) {
      console.error("refreshToken error", error);
      if (autoLogin) {
        const redirectUri = window.location.href;
        authAction === AUTH_ACTION.SIGNUP
          ? register({ redirectUri })
          : login({ redirectUri });
      }
    }
  }, [
    clientId,
    clientDomain,
    ssoApiUrl,
    keyDataPkceCookie,
    autoLogin,
    authAction,
    login,
    register,
  ]);

  const getToken = useCallback(
    async (codeParams: string, dataPkce: any) => {
      try {
        console.log("getToken...");
        const { codeVerifier, clientId, redirectUri } = dataPkce;
        console.log("getToken dataPkce...", { codeParams, dataPkce });
        const response = await apis.auth.getToken({
          grantType: GRANT_TYPE.AUTHORIZATION_CODE,
          clientId,
          codeVerifier,
          code: codeParams,
          redirectUri,
          ssoApiUrl,
        });
        console.log("getToken response...", response);
        deleteSearchParamsAndRedirect(["code", "action"]);
        // if (response.status !== 1)
        //   throw new Error(response.message || "Token fetch failed");
        // removeCookie(KEY_COOKIE_DATA_PKCE, clientDomain);
        // updateContextValue(response?.result);
        // deleteSearchParamsAndRedirect(["code", "action"]);
      } catch (err) {
        console.error("getToken error", err);
      }
    },
    [ssoApiUrl]
  );

  /**
   * Get token when the component mounts
   * When the useSSO is false, it means the SSO is not enabled
   * When the codeParams is not present, it means the user is not in the login process
   * When the authError is present, it means the user is error during the login process
   * When the autoLogin is false, it means the user is not auto login
   */
  useEffect(() => {
    console.log("useEffect getToken...", { codeParams, authError, autoLogin });
    if (!useSSO || !codeParams || authError || !autoLogin) return;

    const dataPkce = getCookie(keyDataPkceCookie) || {};
    const { codeVerifier, clientId, redirectUri } = dataPkce as DataPkceCookie;
    console.log("useEffect getToken dataPkce...", { dataPkce });

    if (codeVerifier && clientId && redirectUri) getToken(codeParams, dataPkce);
  }, [useSSO, codeParams, keyDataPkceCookie, authError, autoLogin, getToken]);

  /**
   * Refresh token when the component mounts
   * When the useSSO is false, it means the SSO is not enabled
   * When the codeParams is present, it means the get token process is ongoing
   * When the authError is present, it means the user is error during the login process
   */
  useEffect(() => {
    console.log("useEffect refreshToken...", { useSSO, codeParams, authError });
    if (useSSO && !codeParams && !authError) refreshToken();
  }, [useSSO, codeParams, authError, refreshToken]);

  /**
   * Remove codeParams from the URL after 10 seconds
   * When the codeParams is present after 10 seconds, it means the user is in the login may be error
   * When the codeParams is removed, it means the user will login successfully or login again
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      const url = new URL(window.location.href);
      if (url.searchParams.has("code")) {
        url.searchParams.delete("code");
        window.location.href = url.toString();
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <SSOContext.Provider
      value={{
        ...contextValue,
        register,
        login,
        loginSocial,
        accountManagement,
        logout,
      }}
    >
      {children}
    </SSOContext.Provider>
  );
};

export { SSOContext, SSOProvider };
