import crypto from "crypto";
import { KEY_COOKIE_DATA_PKCE, TIME_PKCE } from "../constants";
import { removeCookie, setCookie } from "../utils/cookie";
import { createUrlWithParams } from "../utils/url";

interface DataPkceCookie {
  /**
   * This is the code verifier used to generate the code challenge
   */
  codeVerifier: string;
  /**
   * This is the client ID used to authenticate the user
   */
  clientId?: string;
  /**
   * This is the redirect URI used to redirect the user after the login or signup process
   */
  redirectUri: string;
}

const sha256 = (buffer: Buffer): Buffer =>
  crypto.createHash("sha256").update(buffer).digest();

const base64URLEncode = (str: Buffer): string =>
  str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

const renderCodeVerifier = (): string => {
  const codeVerifier = base64URLEncode(crypto.randomBytes(32));
  return codeVerifier;
};

const renderCodeChallenge = (codeVerifier: string): string => {
  const codeChallenge = base64URLEncode(sha256(Buffer.from(codeVerifier)));
  return codeChallenge;
};

// Function to setup PKCE and save the data to the cookie
const setupPkceAndSetCookie = (
  redirectUri: string,
  clientId: string | undefined,
  clientDomain: string | undefined
) => {
  const keyDataPkceCookie = `${KEY_COOKIE_DATA_PKCE}-${clientId}-${clientDomain}`;
  removeCookie(keyDataPkceCookie, clientDomain);
  const codeVerifier = renderCodeVerifier();
  const codeChallenge = renderCodeChallenge(codeVerifier);
  const dataPkce: DataPkceCookie = {
    codeVerifier,
    clientId,
    redirectUri,
  };
  const expiresDate = new Date();
  expiresDate.setDate(expiresDate.getDate() + TIME_PKCE);
  setCookie({
    key: keyDataPkceCookie,
    value: JSON.stringify(dataPkce),
    expires: expiresDate,
    domain: clientDomain,
  });
  return codeChallenge;
};

// Function to construct the URL with the parameters and redirect
const constructUrlAndRedirect = (
  baseUrl: string,
  params: Record<string, any>
) => {
  const url = createUrlWithParams(baseUrl, params);
  window.location.href = url;
};

export {
  renderCodeVerifier,
  renderCodeChallenge,
  setupPkceAndSetCookie,
  constructUrlAndRedirect,
};
