import Cookies from "universal-cookie";

interface SetCookieOptions {
  key: string;
  value: string;
  expires: Date;
  domain?: string;
}

const getCookie = (key: string): string | object | undefined => {
  const cookie = new Cookies();
  const value = cookie.get(key);
  return value;
};

const setCookie = ({ key, value, expires, domain }: SetCookieOptions): void => {
  const cookies = new Cookies();
  cookies.set(key, value, { expires, domain, path: "/" });
};

const removeCookie = (key: string, domain?: string): void => {
  const cookies = new Cookies();
  cookies.remove(key, { domain, path: "/" });
};

const removeCookieFromAllSubdomains = (key: string, domain?: string): void => {
  removeCookie(key);

  const domains = [domain];
  domains.forEach((domain) => {
    removeCookie(key, domain);
  });
};

export { getCookie, setCookie, removeCookie, removeCookieFromAllSubdomains };
